<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-4" id="maintoggledrop" #maintoggledrop>
            <!-- Tabs -->
            <section>
                <div class="text-md-end mb-32">
                    <button type="button" (click)="CreatebulkletterModal()" class="btn p-btn"
                        [disabled]="isCreateLetterEnabled">Create Bulk Letters in
                        Batch</button>
                </div>
                <ngx-spinner size="medium" [type]="typeSelected">
                </ngx-spinner>
                <!-- data table -->
                <div class=" bg-white px-4 py-3 rounded">
                    <div class="table-responsive">
                        <p-table #dt styleClass="p-datatable-gridlines" dataKey="id" [rows]="10" [value]="bulkbatchlist"
                            [resizableColumns]="true" sortMode="multiple" [scrollable]="true" scrollHeight="1000px"
                            [showCurrentPageReport]="true" [paginator]="true" [(first)]="firstpage"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}"
                            [globalFilterFields]="['BatchId','Admin','PartnerName','Office','TemplateName','NoOfLetters','LastUpdatedOn','DocumentStatus', 'Approach']">
                            <ng-template pTemplate="colgroup">
                                <colgroup>
                                    <col style="width:34%">
                                    <col style="width:41%">
                                    <col style="width:43%">
                                    <col style="width:51%">
                                    <col style="width:41%">
                                    <col style="width:53%">
                                    <col style="width:39%">
                                    <col style="width:35%">
                                    <col style="width:34%">

                                </colgroup>
                            </ng-template>

                            <ng-template pTemplate="header">
                                <tr>
                                    <th pResizableColumn pSortableColumn="BatchId">Batch ID<p-sortIcon field="BatchId">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="Admin">Admin<p-sortIcon field="Admin">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="PartnerName">Partner (PIC)<p-sortIcon
                                            field="PartnerName">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="TemplateName">Template Name<p-sortIcon
                                            field="TemplateName">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="NoOfLetters">No of Letters<p-sortIcon
                                            field="NoOfLetters">
                                        </p-sortIcon>
                                    </th>

                                    <th pResizableColumn pSortableColumn="LastUpdatedOn">Last Updated On<p-sortIcon
                                            field="LastUpdatedOn">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="DocumentStatus">Status<p-sortIcon
                                            field="DocumentStatus">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn pSortableColumn="Approach">Approach<p-sortIcon
                                            field="Approach">
                                        </p-sortIcon>
                                    </th>
                                    <th pResizableColumn>Action</th>
                                </tr>
                                <!-- grid searchbox -->
                                <tr>

                                    <th> <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'BatchId', 'contains')"
                                            class="p-column-filter"></th>
                                    <th>
                                        <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'Admin', 'contains')"
                                            class="p-column-filter">
                                    </th>
                                    <th> <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'PartnerName', 'contains')"
                                            class="p-column-filter"></th>
                                    <th> <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'TemplateName', 'contains')"
                                            class="p-column-filter"></th>
                                    <th> <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'NoOfLetters', 'contains')"
                                            class="p-column-filter"></th>
                                    <th> <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'LastUpdatedOn', 'contains')"
                                            class="p-column-filter"></th>
                                    <th> <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'DocumentStatus', 'contains')"
                                            class="p-column-filter"></th>
                                    <th> <input pInputText type="text"
                                            (input)="dt.filter($event.target.value, 'Approach', 'contains')"
                                            class="p-column-filter"></th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-batch>
                                <tr>
                                    <td>{{batch.BatchId}}</td>
                                    <td>{{batch.Admin}}</td>
                                    <td>{{ batch.PartnerCount > 1 ? batch.PartnerName + '...' : batch.PartnerName }}
                                    </td>
                                    <!-- <td>{{batch.Office}}</td> -->
                                    <td>{{batch.TemplateName}}</td>
                                    <td>{{batch.NoOfLetters}}</td>
                                    <td>{{batch.LastUpdatedOn}}</td>
                                    <td>
                                        <span *ngIf="batch.DocumentStatus === 'Draft'" class="status draft"></span>
                                        <span *ngIf="batch.DocumentStatus === 'In Progress'"
                                            class="status inprogress"></span>
                                        <span *ngIf="batch.DocumentStatus === 'In Queue'" class="status draft"></span>
                                        <span *ngIf="batch.DocumentStatus === 'Completed - Batch'"
                                            class="status completed"></span>
                                        {{batch.DocumentStatus === 'Completed - Batch' ? 'Completed' :
                                        batch.DocumentStatus}}
                                    </td>
                                    <td>
                                        {{batch.Approach}}
                                    </td>
                                    <td>
                                        <button [hidden]="(batch.DocumentStatus != 'Draft')" (click)="
                                        getbatchDetailsbyid(batch.BatchId)" class="bg-transparent mx-1"
                                            pTooltip="Edit"><img src="./assets/images/edit.png"
                                                alt="Edit-icon"></button>

                                        <button [hidden]="(batch.DocumentStatus != 'Draft') || this.onlySigner==true"
                                            class=" bg-transparent mx-1" (click)="deleteBatchModal(batch.BatchId)"
                                            pTooltip="Delete"><img src="./assets/images/delete-icon.png"
                                                alt="Delete-icon"></button>
                                        <button (click)="viewPartnerOfficeList(batch.BatchId, batch.DocumentStatus)"
                                            pTooltip="info" class="bg-transparent mx-1" data-bs-toggle="modal"
                                            data-bs-target="#edit-field"><img src="assets/images/info-circle.png"
                                                alt="Eye-icon" width="16" height="16"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="12" style="text-align: center;">No bulk letter batches found</td>
                                </tr>
                            </ng-template>

                        </p-table>

                    </div>
                </div>
            </section>
            <section>
                <div class="row mt-2" *ngIf="this.showBtachProgressData == true">
                    <div class="col-md-4">
                        <p class="mb-0"> <span class="s-text fw-semibold">Batch in progress :
                            </span>
                            <ng-container *ngIf="this.currentBatchId; else showNull">
                                <span>{{this.currentBatchId}} Approx. completion time :
                                    {{this.currentBatchCompletionOn}} ET </span>
                            </ng-container>
                            <ng-template #showNull>None</ng-template>
                        </p>
                    </div>
                    <div class="col-md-3">
                        <p class="mb-0"><span class="s-text fw-semibold">Number of batches in queue : </span>
                            <ng-container *ngIf="this.totalBatchCount ; else batchCountZero">
                                <span>{{this.totalBatchCount}}</span>
                            </ng-container>
                        </p>

                        <ng-template #batchCountZero>0</ng-template>
                    </div>
                    <div class="col-md-5">
                        <p style="width: fit-content;" class="mb-0 ms-auto"> <span class="s-text fw-semibold">Next batch
                                : </span>
                            <ng-container *ngIf="this.nextBatchId ; else showNull"><span>{{this.nextBatchId}} Approx.
                                    start time : {{this.nextBatchStartTime}} ET</span></ng-container>
                            <ng-template #showNull>None</ng-template>

                        </p>
                    </div>
                </div>
            </section>
        </main>
    </div>
</section>
<!-- Partner Liat Modal -->
<div class="modal" tabindex="-1" id="bulk-letter-edit-field" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayPartnerOfficeList}">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Partner (PIC) & Office Details</h5>
                <button type="button" class="btn-close shadow-none" (click)="closePartnerOfficeList()"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <!-- <p><span class="err-color">Note: </span>All the fields are mandatory</p> -->
            <div class="modal-body p-0">
                <p-table #dt1 styleClass="p-datatable-gridlines" dataKey="id" [rows]="10" [value]="partnerOfficeList"
                    [resizableColumns]="true" sortMode="multiple" [scrollable]="true" scrollHeight="1000px"
                    [showCurrentPageReport]="true" [(first)]="firstpage"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    [globalFilterFields]="['BatchId','PartnerName','Office']">
                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col style="width:25%">
                            <col style="width:30%">
                            <col style="width:30%">

                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn>Batch ID </th>
                            <th pResizableColumn> Partner (PIC)</th>
                            <th pResizableColumn>Office</th>

                        </tr>
                        <!-- grid searchbox -->
                        <!-- <tr>
    
                            <th> <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'BatchId', 'contains')"
                                    class="p-column-filter"></th>
    
                            <th> <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'PartnerName', 'contains')"
                                    class="p-column-filter"></th>
                            <th> <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'Office', 'contains')"
                                    class="p-column-filter"></th>
    
                        </tr> -->
                    </ng-template>
                    <ng-template pTemplate="body" let-batch>
                        <tr>
                            <td>{{batch.BatchId}}</td>
                            <td>{{batch.PartnerName}}</td>
                            <td>{{batch.Office}}</td>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="12" style="text-align: center;">No bulk letter batches found</td>
                        </tr>
                    </ng-template>

                </p-table>
                <hr [hidden]=hideBatchProgressInfo>
                <div class="mt-2" [hidden]=hideBatchProgressInfo>
                    <h5 class="modal-title fs-16 highlight fw-semibold m-0 pb-2">Batch Processing Details</h5>
                    <table aria-labelledby="table-description" class="batch-info" style="width: 100%;">
                        <tbody>
                            <tr>
                                <td>
                                    <p class="fw-semibold s-text">Created On</p>
                                </td>
                                <td>
                                    <p class="s-text fw-medium">: {{ createdOn }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="fw-semibold s-text">Process Started On</p>
                                </td>
                                <td>
                                    <p class="s-text fw-medium">: {{ processStartedOn }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="fw-semibold s-text">Process Completed On</p>
                                </td>
                                <td>
                                    <p class="s-text fw-medium">: {{ processCompletedOn }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr [hidden]=hideBatchProgressInfo>
            </div>
            <div class="modal-footer border-0 pb-0 pt-3 px-0">
                <div>
                    <button type="button" (click)="closePartnerOfficeList()" class="btn p-btn-outline me-3"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->
<div class="modal" tabindex="-1" id="deleteModal" [ngStyle]="{'display':displayDeleteModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                <button type="button" (click)="onCloseDeleteModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to delete this engagement letter batch?</p>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onCloseDeleteModal()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">No</button>
                <button type="button" (click)="deleteBatchById()" class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- warning Modal -->
<div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" (click)="closeWarningModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{warningMessage}}</p>
                <button type="button" (click)="closeWarningModal()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button type="button" (click)="closeSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="signingblock" #siginingcontent>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Console log error Modal-->
<div class="modal" tabindex="-1" id="consoleerror" [ngStyle]="{'display':displayConsoleLogerror}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="ondefaultLogError()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <div class="fs-16">
                    <h5>Sorry, something went wrong.</h5><br>
                    <h5>To continue please logout, close the browser, login again and resume the work on
                        this letter.</h5>
                    <h5>If any problem occurs again in this letter, please take a full screen screenshot
                        showing complete browser along with date time and share with IT Support.</h5>
                </div>
                <button type="button" (click)="ondefaultLogError()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Createletter Modal -->
<div class="modal" tabindex="-1" id="add-attachment" [ngStyle]="{'display':displayCreateletter}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <button type="button" (click)="closeCreateLetterModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body selection-mode p-0">
                <div>
                    <div class="text-center mb-3">
                        <h3 class="fw-semibold text-dark">Select Creation Mode</h3>
                        <p class="form-check-label">Choose the appropriate mode to start creating your Letter</p>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 point-cursor" (click)="createLetterForm('Clients')">
                            <div id="client" class="border p-3 rounded-3 h-100 Creation-Mode"
                                [class.active]="isClientsActive">
                                <div class="text-center mb-2">
                                    <p class="text-center"><img src="../../../assets/images/ph_user.svg" alt="user"></p>

                                    <h5 class="my-3 fw-semibold s-text">Create Letters Based on <br> Clients</h5>
                                </div>
                                <ul>
                                    <li class="mb-2 form-check-label">Select as many clients as you want and select
                                        their respective template.</li>
                                    <li class="mb-2 form-check-label">Engage prepares 1 letter for each client.</li>
                                    <li class="mb-2 form-check-label">Engage sends link for all the letters in one
                                        single email.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 point-cursor" (click)="createLetterForm('Templates')">
                            <div id="template" class="border p-3 rounded-3 h-100 Creation-Mode"
                                [class.active]="isTemplateActive">
                                <div class="text-center mb-2">
                                    <p class="text-center"><img src="../../../assets/images/lucide_layout-template.svg"
                                            alt="template"></p>
                                    <h5 class="my-3 fw-semibold s-text">Create Letters Based on <br> Templates</h5>
                                </div>
                                <ul>
                                    <li class="mb-2 form-check-label">Select one or more Partner(s).</li>
                                    <li class="mb-2 form-check-label">Select one template and its return type code.</li>
                                    <li class="mb-2 form-check-label">Clients for the selected partner and template will
                                        be loaded.</li>
                                    <li class="mb-2 form-check-label">Engage prepares 1 letter for each client.</li>
                                    <li class="mb-2 form-check-label">Engage sends 1 email for each client with 1 link.
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="modal-footer border-0 pb-0 pt-3 px-0">
                <div>
                    <!-- <button type="button" class="btn p-btn-outline me-3" data-bs-dismiss="modal">Close</button> -->
                    <button type="button" (click)="CreateBulkletter()" [disabled]="disabelCreationModeButton"
                        class="btn p-btn">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div [innerHTML]="trustedHtml"></div>